$(document).ready(function () {
  if($('#datafinder-table').length > 0) {
    $('#datafinder-table').dataTable({
      "processing": true,
      "serverSide": true,
      "deferRender": true,
      "dom" : '<<"row"lf><"row"t><"row"ip>>',
      "ajax": {
        "url": $("#datafinder-table").data("source"),
        "data" : function(d){
          return $.extend({}, d, { "min_inc" : $("#minIncome").val(), "max_inc" : $("#maxIncome").val(), "min_rev" : $("#minRevenue").val(), "max_rev" : $("#maxRevenue").val() })
        }

      },
      "pagingType": "full_numbers",
      "columns": [
        {"data": "name"},
        {"data": "address"},
        {"data": "city"},
        {"data": "state"},
        {"data": "zip"},
        {"data": "revenue", "defaultContent": "-"},
        {"data": "ntee_cd", "defaultContent": "-"}
      ],
      "columnDefs": [{
        "targets" : [1],
        "render" : function( data, type, row ){
          return row.address + ", " + row.city + ", " + row.state + " " + row.zip
        }
      },
      {
        "targets": [5, 6],
        "data" : null,
        "defaultContent" : '-',
        "render": $('#datafinder-table').DataTable.render.number(',', '.', 2, '$')
        //render: DataTable.render.number( ',', '.', 2, '$' )
      },
      {
        "visible": false,
        "targets": [2, 3, 4]
      },
      {
        "width" : "28%",  "targets" : [0, 1]
      }
      ]
      // pagingType is optional, if you want full pagination controls.
      // Check dataTables documentation to learn more about
      // available options.
    });

    var dataFinderTable = $('#datafinder-table').DataTable();

    updateLengthDom();
    updateSearchDOM();

    function updateSearchDOM(){
      var searchContainer = $('#datafinder-table_filter');
      var lengthContainer = $('#datafinder-table_length');
      searchContainer.addClass('flex-grow-1')
      lengthContainer.addClass('flex-grow-1')
      var searchInput = searchContainer.find('input');
      var searchLabel = searchContainer.find('label');
      var updatedSearchLabelCopy = $('#search-filter label').text();
      searchInput.detach();
      searchLabel.html('<span class="show-for-sr">'+ updatedSearchLabelCopy +'</span>');
      searchInput.appendTo(searchLabel).attr('placeholder', updatedSearchLabelCopy);
      searchLabel.css('width', '100%');
      searchContainer.prependTo('#datafinder-filters');
      $('#search-filter').remove();
    }

    function updateLengthDom(){
      var lengthContainer = $('#datafinder-table_length');
      var lengthInput = lengthContainer.find('select');
      var lengthLabel = lengthContainer.find('label');
      lengthInput.detach();
      var lengthLabelCopy = lengthContainer.find('label').text();
      var lengthLabelWords = lengthLabelCopy.split(' ');
      lengthLabel.html('');
      lengthLabel.append('<span>'+ lengthLabelWords[0] +'</span>');
      lengthLabel.append(lengthInput);
      lengthLabel.append('<span>'+ lengthLabelWords[2] +'</span>');
      lengthLabel.addClass('d-flex align-items-center justify-content-center gap-2 flex-grow-1');
      $('#length-filter').remove();;
      lengthContainer.insertBefore('#export-btn');;
    }

    // 2. Revenue Range
    $("#btn-revenue").on("click", function () {
      var min = parseInt($("#minRevenue").val());
      var max = parseInt($("#maxRevenue").val());
      var range = abbrNum(min) + " - " + abbrNum(max);
      var revenue = dataFinderTable.column(5).data();
      var matches = [];

      for (var p = 0; p < revenue.length; p++) {
        i = parseInt(revenue[p].toString().replace(/[^0-9\.-]+/g, ""));
        if (i >= min && i <= max && !isNaN(i)) {
          matches.push(i);
        }
      }
      var filter = $(this).parent().siblings(".filter-selected");
      filter.children("span").text(range);
      filter.removeClass("d-none");
      dataFinderTable.ajax.reload();
      $(this).parent().parent().addClass('revenue-active');
    });

    function abbrNum(number) {
      var abbrev = [ "k", "m", "b", "t" ];
      for (var i=abbrev.length-1; i>=0; i--) {
        var size = Math.pow(10,(i+1)*3);
        if(size <= number) {
          number = Math.round(number/size);
          if((number == 1000) && (i < abbrev.length - 1)) {
              number = 1;
              i++;
          }
          number += abbrev[i];
          break;
        }
      }
      return number;
    }

    $(".btn-clear-filter").on("click", function () {
      var filter = $(this).parent(); // container div
      filter.contents().filter(function () {
        return this.nodeType === 3;
      }).remove();
      filter.addClass("d-none");

      if ($(this).closest("#revenue-filter").length) {
        $("#minRevenue").val("");
        $("#maxRevenue").val("");
        dataFinderTable.column(5).search("").draw();
        $('#revenue-filter').removeClass('revenue-active');
      }
    });
  }
})


