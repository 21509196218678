// Variables for pagination

$(document).ready(function() {

  var current_page = 0;
  var per_page = 25;

  // Apply for Jobs on Job Show page.
  const applyForJob = document.querySelector('.apply');

  if (applyForJob) {
    const applyInstructions = document.getElementById('apply_instructions');
    applyInstructions.classList.add('d-none');

    applyForJob.addEventListener('click', function () {
      applyInstructions.classList.remove('d-none');
    });
  }

  // Search filters on Job Index page
  const searchable = document.querySelectorAll('.searchable');

  if (searchable) {
    for (var index = 0; index < searchable.length; index++) {
      searchable[index].addEventListener('change', function (event) {
        updateSearchResults();
      });
    }
  }

  // Collects current values from search fields and updates job search
  // listing section.
  function updateSearchResults() {
    current_page = 0;
    loadJobs(false);
    window.history.pushState(null, null, '?' + encodeQueryData(getCurrentValues()));
  }

  // Polls for more jobs using the provided search values if available.
  const linkLoadMore = document.querySelector('.link_load_more');

  if (linkLoadMore) {
    linkLoadMore.addEventListener('click', function(event) {
      event.preventDefault();
      loadMoreJobs();
    });
  }

  function loadMoreJobs() {
    current_page++;
    loadJobs(true);
  }

  function loadJobs(append = false) {
    var xhttp = new XMLHttpRequest();

    xhttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        var jobList = document.querySelector('.job_list');
        const loadMoreDiv = document.querySelector('.load_more');

        const beforeCount = jobList.children.length;

        while (!append && jobList.children.length > 1) {
          jobList.removeChild(jobList.firstChild);
        }

        loadMoreDiv.insertAdjacentHTML('beforebegin', this.responseText);

        const afterCount = jobList.children.length;

        if (beforeCount == afterCount || afterCount < 25) {
          loadMoreDiv.classList.add('d-none');
        } else {
          loadMoreDiv.classList.remove('d-none');
        }
      }
    };

    var url = '/api/jobs/search?' + encodeQueryData(getCurrentValues())

    xhttp.open('GET', url, true);
    xhttp.send();
  }

  function getCurrentValues() {
    const titleField = document.getElementById('title').value;
    const locationField = document.getElementById('location').value;
    const categoryField = document.getElementById('category');
    const cateogryValue = categoryField.options[categoryField.selectedIndex].value;

    var jobTypes = [];
    var jobTypeCheckboxes = document.getElementsByClassName('job_type_checkbox');

    for (var counter = 0; counter < jobTypeCheckboxes.length; counter++) {
      if (jobTypeCheckboxes[counter].checked) {
        jobTypes.push(jobTypeCheckboxes[counter].value);
      }
    }

    return {
      'page': current_page,
      'per_page': per_page,
      "title": titleField,
      "location": locationField,
      "category": cateogryValue,
      "job_types": jobTypes
    }
  }

  function encodeQueryData(data) {
    const ret = [];

    for (let d in data) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }

    return ret.join('&')
  }
})
